import { Component, OnInit } from '@angular/core';
import {  Ae2CarouselConfig } from '@angularecommerce/core/components/carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  carouselConfig: Ae2CarouselConfig;
  open: boolean = false;
  constructor() {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.carouselConfig = new Ae2CarouselConfig();
    this.carouselConfig.useDottedOverLay = false;
    this.carouselConfig.intervalTime = 5000;

  }
  openDialog(): void {
    this.open = true;
    setTimeout(() => {
      this.open = false;
    }, 100);
  }

}
