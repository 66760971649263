/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../node_modules/@angularecommerce/core/components/carousel-container/carousel-container.component.ngfactory";
import * as i9 from "@angularecommerce/core/components/carousel-container/carousel-container.component";
import * as i10 from "@angularecommerce/core/services/carousel-banners/carousel-banners.service";
import * as i11 from "@angularecommerce/core/components/carousel/carousel-overlay-content.directive";
import * as i12 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ae2Carousel__overlay-content-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "ae2Carousel__overlay-content-default__button"], ["mat-raised-button", ""], ["routerLink", "/pacotes"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Confira os pacotes"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "ae2Carousel__overlay-content-default__button"], ["mat-raised-button", ""], ["routerLink", "/agenda"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(6, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(7, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Veja a nossa agenda"]))], function (_ck, _v) { var currVal_2 = "/pacotes"; _ck(_v, 2, 0, currVal_2); var currVal_5 = "/agenda"; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 7).disabled || null); var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); }); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ae2-carousel-container", [["carouselCode", "home"]], null, null, null, i8.View_Ae2CarouselContainerComponent_0, i8.RenderType_Ae2CarouselContainerComponent)), i1.ɵdid(1, 49152, null, 1, i9.Ae2CarouselContainerComponent, [i10.Ae2CarouselBannersService, i1.ChangeDetectorRef], { config: [0, "config"], carouselCode: [1, "carouselCode"] }, null), i1.ɵqud(335544320, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 1, null, View_HomeComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.Ae2CarouselOverlayContentDirective, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carouselConfig; var currVal_1 = "home"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i12.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i12.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
