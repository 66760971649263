import { Component, OnInit } from '@angular/core';
import {
  Ae2HeaderWidgetConfig,
  Ae2HeaderLinkToPage,
  Ae2HeaderMenu,
  Ae2HeaderSubmenu,
} from '@angularecommerce/core/components/header-widget';
import {
  Ae2SignUpStepAddress,
  Ae2SignUpStepFitness,
  Ae2SignUpStepProfile,
  Ae2SignUpStepSignup
} from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import { Ae2SignUpStepperConfig, Ae2SignUpField } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpFieldValidator, Ae2SignupFieldTypes } from '@angularecommerce/core/components/sign-up-steps';

export class CustomSignUpStepFitness extends Ae2SignUpStepFitness {

  constructor() {
      super();

      this.fields = [
          new Ae2SignUpField({
              name: 'weight',
              label: 'core.signUpSteps.stepper.labels.weight',
              validators: new Ae2SignUpFieldValidator({ required: false, mask: '999', ValidateWeight: true }),
              row: 1,
              size: '48.5%'
          }),
          new Ae2SignUpField({
              name: 'height',
              label: 'core.signUpSteps.stepper.labels.height',
              validators: new Ae2SignUpFieldValidator({ required: false, mask: '999', ValidateHeight: true }),
              row: 1,
              size: '48.5%'
          }),
          new Ae2SignUpField({
              name: 'tshirtSize',
              internalname: 'tshirt_size',
              label: 'core.signUpSteps.stepper.labels.tshirtSize',
              validators: new Ae2SignUpFieldValidator({
                  type: Ae2SignupFieldTypes.SELECT, required: false, options: [
                      { value: 'Baby Look', label: 'core.global.tshirtSizes.babyLook' },
                      { value: 'P', label: 'core.global.tshirtSizes.p' },
                      { value: 'M', label: 'core.global.tshirtSizes.m' },
                      { value: 'G', label: 'core.global.tshirtSizes.g' },
                      { value: 'GG', label: 'core.global.tshirtSizes.gg' }
                  ]
              }),
              row: 1,
              size: '48.5%'
          })
      ];
  }
}

export const CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
  new Ae2SignUpStepSignup(),
  new Ae2SignUpStepProfile(),
  new Ae2SignUpStepAddress(),
  new CustomSignUpStepFitness()
]);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
  signupConfig: Ae2SignUpStepperConfig;

  constructor() {

  }
  ngOnInit(): void {
    this.headerConfig = new Ae2HeaderWidgetConfig();
    this.headerConfig.menuItems = new Ae2HeaderMenu();
    this.headerConfig.menuItems.items = [
      new Ae2HeaderSubmenu('Sobre nós', [
        new Ae2HeaderLinkToPage('Quem somos', '/quem-somos'),
        // new Ae2HeaderLinkToPage('Treino funcional', '/treino-funcional'),
        new Ae2HeaderLinkToPage('Instrutores', '/instrutores'),
      ]),
      new Ae2HeaderLinkToPage('Pacotes', '/pacotes'),
      new Ae2HeaderLinkToPage('Agenda', '/agenda'),


    ];
    this.headerConfig.transparentOnRoutes = [];
    this.headerConfig.backgroundColor = '#ffffff';
    this.signupConfig = CustomAe2SignupConfig;
  }

}
