var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Ae2HeaderWidgetConfig, Ae2HeaderLinkToPage, Ae2HeaderMenu, Ae2HeaderSubmenu, } from '@angularecommerce/core/components/header-widget';
import { Ae2SignUpStepAddress, Ae2SignUpStepFitness, Ae2SignUpStepProfile, Ae2SignUpStepSignup } from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import { Ae2SignUpStepperConfig, Ae2SignUpField } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpFieldValidator, Ae2SignupFieldTypes } from '@angularecommerce/core/components/sign-up-steps';
var CustomSignUpStepFitness = /** @class */ (function (_super) {
    __extends(CustomSignUpStepFitness, _super);
    function CustomSignUpStepFitness() {
        var _this = _super.call(this) || this;
        _this.fields = [
            new Ae2SignUpField({
                name: 'weight',
                label: 'core.signUpSteps.stepper.labels.weight',
                validators: new Ae2SignUpFieldValidator({ required: false, mask: '999', ValidateWeight: true }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'height',
                label: 'core.signUpSteps.stepper.labels.height',
                validators: new Ae2SignUpFieldValidator({ required: false, mask: '999', ValidateHeight: true }),
                row: 1,
                size: '48.5%'
            }),
            new Ae2SignUpField({
                name: 'tshirtSize',
                internalname: 'tshirt_size',
                label: 'core.signUpSteps.stepper.labels.tshirtSize',
                validators: new Ae2SignUpFieldValidator({
                    type: Ae2SignupFieldTypes.SELECT, required: false, options: [
                        { value: 'Baby Look', label: 'core.global.tshirtSizes.babyLook' },
                        { value: 'P', label: 'core.global.tshirtSizes.p' },
                        { value: 'M', label: 'core.global.tshirtSizes.m' },
                        { value: 'G', label: 'core.global.tshirtSizes.g' },
                        { value: 'GG', label: 'core.global.tshirtSizes.gg' }
                    ]
                }),
                row: 1,
                size: '48.5%'
            })
        ];
        return _this;
    }
    return CustomSignUpStepFitness;
}(Ae2SignUpStepFitness));
export { CustomSignUpStepFitness };
export var CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new Ae2SignUpStepSignup(),
    new Ae2SignUpStepProfile(),
    new Ae2SignUpStepAddress(),
    new CustomSignUpStepFitness()
]);
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
        this.headerConfig = new Ae2HeaderWidgetConfig();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderSubmenu('Sobre nós', [
                new Ae2HeaderLinkToPage('Quem somos', '/quem-somos'),
                // new Ae2HeaderLinkToPage('Treino funcional', '/treino-funcional'),
                new Ae2HeaderLinkToPage('Instrutores', '/instrutores'),
            ]),
            new Ae2HeaderLinkToPage('Pacotes', '/pacotes'),
            new Ae2HeaderLinkToPage('Agenda', '/agenda'),
        ];
        this.headerConfig.transparentOnRoutes = [];
        this.headerConfig.backgroundColor = '#ffffff';
        this.signupConfig = CustomAe2SignupConfig;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
