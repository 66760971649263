<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
    <ng-template ae2CarouselOverlayContent 
    let-title="title" 
    let-description="description" 
    let-ctaLabel="ctaLabel" 
    let-ctaUrl="ctaUrl">
        <div class="ae2Carousel__overlay-content-default">
            <button class="ae2Carousel__overlay-content-default__button" mat-raised-button routerLink="/pacotes">Confira os pacotes</button>
            <button class="ae2Carousel__overlay-content-default__button" mat-raised-button routerLink="/agenda">Veja a nossa agenda</button>
        </div>
    </ng-template>
</ae2-carousel-container>

