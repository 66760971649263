<ae2-site-settings>
    <ng-template #ae2SiteSettingsContent let-site="site">
        <footer>
            <section class="footer-top">
                <div class="footer-top-links" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center start" fxLayoutAlign.xs="center center" fxLayoutGap.xs="30px">
                    <!-- <div class="footer-top-logo" class="mobileLink" fxFlex.sm="33%" fxFlex="20%" fxFlex.xs="auto">
                        <img class="footer-top-logo-image" src="/assets/images/logo-footer.png" alt="logo tribvs">
                    </div> -->
                    <div class="mobileLink" fxFlex="20%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Tribvs</h2>
                        <mat-list role="list">
                            <mat-list-item role="listitem" routerLink="/">Início</mat-list-item>
                            <mat-list-item role="listitem" routerLink="/pacotes">Pacotes</mat-list-item>
                            <mat-list-item role="listitem" routerLink="/agenda">Agenda</mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mobileLink" fxFlex="20%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Sobre nós</h2>
                        <mat-list role="list">
                            <mat-list-item role="listitem" routerLink="/quem-somos">Quem somos</mat-list-item>
                            <!-- <mat-list-item role="listitem" routerLink="/treino-funcional">Treino Funcional</mat-list-item> -->
                            <mat-list-item role="listitem" routerLink="/instrutores">Instrutores</mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mobileLink" fxFlex="20%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Fale conosco</h2>
                        <mat-list role="list">
                            <mat-list-item role="listitem"><a href="tel:{{site?.phone}}">{{site?.phone}}</a></mat-list-item>
                            <mat-list-item role="listitem"><a href="mailto:{{site?.contactEmail}}">{{site?.contactEmail}}</a></mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mobileLink" fxFlex="20%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Ajuda</h2>
                        <mat-list role="list">
                            <mat-list-item role="listitem" routerLink="/politica-de-privacidade">Política de Privacidade</mat-list-item>
                            <mat-list-item role="listitem"><a taget="_blank" href="/media/terms-and-conditions/termos-e-condicoes.pdf">Termos e Condições</a></mat-list-item>
                        </mat-list>
                    </div>
                    <div class="mobileLink" fxFlex="20%" fxFlex.sm="33%" fxFlex.xs="auto">
                        <h2 class="footer-top-title">Redes Sociais</h2>
                        <div fxLayout fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
                            <div matLine *ngIf="site.socialTwitter" (click)="targetLint(site.socialTwitter)">
                                <i class="iconFooter fa fa-twitter" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialInstagram" (click)="targetLint(site.socialInstagram)">
                                <i class="iconFooter fa fa-instagram" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialFacebook" (click)="targetLint(site.socialFacebook)">
                                <i class="iconFooter fa fa-facebook" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialSpotify" (click)="targetLint(site.socialSpotify)">
                                <i class="iconFooter fa fa-spotify" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialYoutube" (click)="targetLint(site.socialYoutube)">
                                <i class="iconFooter fa fa-youtube" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialLinkedin" (click)="targetLint(site.socialLinkedin)">
                                <i class="iconFooter fa fa-linkedin" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialVimeo" (click)="targetLint(site.socialVimeo)">
                                <i class="iconFooter fa fa-vimeo" aria-hidden="true"></i>
                            </div>
                            <div matLine *ngIf="site.socialFlickr" (click)="targetLint(site.socialFlickr)">
                                <i class="iconFooter fa fa-flickr" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="footer-bottom">
                <div class="footer-bottom-content" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
                    <div class="footerText">Tribvs © 2018. Todos os direitos reservados.</div>
                    <div>
                        <a class="footer-bottom-content-link" href="https://angulare.app/?utm_source=cws&utm_campaign=tribvs" target="_blank">Powered by Angular e-Commerce</a>
                    </div>
                </div>
            </section>
        </footer>
    </ng-template>
</ae2-site-settings>
