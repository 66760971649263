import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_INITIALIZERS } from './initializers';
import { APP_STORE_MODULES } from './stores-modules';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderModule } from './shared/components/header/header.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { Ae2CoreModule } from '@angularecommerce/core/modules/core';
import { Ae2MyAccountGlobalConfig, MenuInterface, AE2_MY_ACCOUNT_CONFIG_TOKEN } from '@angularecommerce/core/components/my-account';

/**
  * Comente essa linha quando precisar desabilitar o service worker
  */
// import { Ae2SwService } from '@angularecommerce/core/services/sw';

/**
* Comentado pois o uso de service worker provoca erros na dashboard,
* por estar no mesmo domínio
*/
// import { ServiceWorkerModule } from '@angular/service-worker';

export class MyAccountCustomConfig extends Ae2MyAccountGlobalConfig {
  // Menus
  menu: MenuInterface[] = [];
  showFormPerformance: boolean = false;

  constructor() {
    super();
    this.menu.push({ name: 'Meus dados', link: 'meus-dados', icon: 'account_circle' });
    this.menu.push({ name: 'Alterar minha senha', link: 'alterar-senha', icon: 'lock' });
    this.menu.push({ name: 'Cartões de Crédito', link: 'metodos-pagamento', icon: 'credit_card' });
    this.menu.push({ name: 'Meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' });
    this.menu.push({ name: 'Minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' });
    this.menu.push({ name: 'Meus eventos', link: 'meus-eventos', icon: 'event' });
    // this.menu.push({ name: 'Horário Fixo', link: 'meus-eventos-automaticos', icon: 'event' });
    this.menu.push({ name: 'Meus créditos', link: 'meus-creditos', icon: 'account_balance' });
    this.menu.push({ name: 'Compartilhamento de Créditos', link: 'compartilhar-creditos', icon: 'share' });
    // this.menu.push({ name: 'Dados de performance', link: 'dados-de-performance', icon: 'timeline' });
  }
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,

    /**
    * Comente essa linha quando precisar desabilitar o service worker
    */
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),

    AppRoutingModule,

    Ae2CoreModule,
    APP_STORE_MODULES,
    APP_INITIALIZERS,

    CommonModule,
    HeaderModule,
    FooterModule,
    RouterModule,
  ],
  providers: [
    { provide: AE2_MY_ACCOUNT_CONFIG_TOKEN, useClass: MyAccountCustomConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  /**
  * Comente essa linha quando precisar desabilitar o service worker
  */
  // constructor(ae2SwService: Ae2SwService) {

  //   /**
  //   * Comente essa linha quando precisar desabilitar o service worker
  //   */
  //   ae2SwService.checkForUpdate();
  // }

}
